<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
            <b-card class="text-center">
              <b-card-title v-if="range_year" class="my-0">
                Payments for {{ year }}
              </b-card-title>
              <b-card-title v-else class="my-0">
                Payments from {{ date_start | moment("MM/DD/YYYY") }} to {{ date_end | moment("MM/DD/YYYY") }}
              </b-card-title>
              <b-button-group class="my-2">
                <b-button @click="prevDate()" variant="outline-primary"> 
                  <font-awesome-icon icon="angle-double-left"/>
                </b-button>
                <b-button :pressed="range_year" @click="setRangeYear(true)" variant="outline-primary">
                  Year
                </b-button>
                <b-button :pressed="!range_year" @click="setRangeYear(false)" variant="outline-primary">
                  Month
                </b-button>
                <b-button @click="nextDate()" variant="outline-primary">
                  <font-awesome-icon icon="angle-double-right"/>
                </b-button>
              </b-button-group>
                <table class="table table-sm table-hover table-fit text-left">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>From</th>
                            <th>Notes</th>
                            <th>Amount</th>
                            <th>
                              <button class="btn btn-success btn-sm py-0 float-right text-white" @click="newPayment()">
                                <font-awesome-icon icon="plus"/>
                                New
                              </button>
                            </th>
                        </tr>
                    </thead>
                    <transition name="fade">
                    <tbody v-if="is_loaded">
                      <tr v-for="payment in payments_sorted" :key="payment.id">
                        <td>{{ payment.week_of | moment("MM/DD/YYYY") }}</td>
                        <td v-if="payment.family != null">{{ payment.family.name }} Family</td>
                        <td v-else-if="payment.student != null">{{ payment.student.first_name }} {{ payment.student.last_name }}</td>
                        <td v-else><b>ERR</b></td>
                        <td>{{ payment.notes }}</td>
                        <td>${{ payment.amount / 100}}</td>
                        <td style="width:7%">
                          <button class="btn btn-primary btn-sm py-0 text-white float-right" @click="editPayment(payment.id)">
                            <font-awesome-icon icon="cog"/> Edit
                          </button>

                        </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td class="text-right">
                          <b>Total:</b>
                        </td>
                        <td><b>${{ total / 100 }}</b></td>
                        <td></td>
                      </tr>
                    </tbody>
                    </transition>
                </table>
                <transition name="fade">
                  <div v-if="!is_loaded" class="loading">
                  <font-awesome-icon icon="circle-notch" size="3x" spin style="color:white"/>
                  </div>
              </transition>
            </b-card>
            </div>
        </div>

    <PaymentModal ref="modal" @newPayment="onNewPayment" @delPayment="onDelPayment">
    </PaymentModal>
    </div>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";
import PaymentModal from "../components/PaymentModal"
const PaymentsAPI = ApiFactory.get("payments");
var _ = require('lodash');

export default {
  data() {
    return {
      payments: [],
      year: new Date().getUTCFullYear(),
      month: new Date().getUTCMonth() + 1,
      range_year: false,
      is_loaded: false
    }
  },

  computed:{

    payments_sorted: function(){
      return _.orderBy(this.payments, ['week_of'])
    },

    total: function(){
      if (this.payments.length > 0){
        var reduced = this.payments.reduce((a, b) => ({amount: a.amount + b.amount}));
        return reduced.amount;
      }else{
        return 0;
      }
    },

    date_start: function(){
      if (this.range_year){
        return `${this.year}-01-01`;
      }else{
        return `${this.year}-${this.month}-01`;
      }
      
    },

    date_end: function(){
      if (this.range_year){
        return `${this.year}-12-31`;
      }else{
         var next_month = new Date(this.year, this.month, 0)
        return `${next_month.getFullYear()}-${(next_month.getMonth()+1).toString().padStart(2, "0")}-${next_month.getDate()}`;
      }
    }
  },

  created: function(){
    this.getPayments();
  }, 

  methods:{
    async getPayments(){
      this.is_loaded = false;
      PaymentsAPI.get(this.date_start, this.date_end).then((resp) => {
        this.payments = resp.data;
        this.is_loaded = true;
      });
    },

    editPayment(paymentID){
      this.$refs.modal.edit(paymentID)
    },

    newPayment(){
      var startDate = null;
      var now = new Date()
      if (this.range_year && this.year == now.getFullYear()){
        startDate = `${now.getFullYear()}-${(now.getMonth()+1).toString().padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`
      }else if (this.range_year){
        startDate = this.date_start;
      }else if (this.year == now.getFullYear() && this.month == (now.getMonth()+1)){
        startDate = `${now.getFullYear()}-${(now.getMonth()+1).toString().padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`
      }else{
        startDate = this.date_start;
      }
      this.$refs.modal.new(startDate);
    },

    onNewPayment(data){
      var currentIndex = null;
      this.payments.forEach((payment, i) => {
        if (payment.id == data.id){
          currentIndex = i;
        }
      });

      if (currentIndex != null){
        this.payments.splice(currentIndex, 1);
      }
      this.payments.push(data)
    },

    onDelPayment(paymentID){
      var currentIndex = null;
      this.payments.forEach((payment, i) => {
        if (payment.id == paymentID){
          currentIndex = i;
        }
      });

      if (currentIndex != null){
        this.payments.splice(currentIndex, 1);
      }
    },

    setRangeYear(state){
      if (state == this.range_year){
        return;
      }
      this.range_year = state;
      this.getPayments()
    },

    nextDate(){
      if (this.range_year){
        this.year += 1;
      }else{
        this.month += 1;
        if (this.month > 12){
          this.month = 1;
          this.year += 1;
        }
      }
      this.getPayments()
    },

    prevDate(){
      if (this.range_year){
        this.year -= 1;
      }else{
        if (this.month <= 1){
          this.month = 12;
          this.year -= 1;
        }else{
          this.month -= 1;
        }
      }
      this.getPayments()
    }

  },

  components: {
    PaymentModal
  }

}
</script>

<style scoped>
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  border-radius: 0.5em;
 
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .75s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}



</style>