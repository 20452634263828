<template>
  <b-modal ref="paymentModal" id="payment-modal" v-bind:title="id == null ? 'New Payment' : 'Edit Payment'" hide-footer>
    <b-form @submit="submit" @reset="reset" class="w-100">

        <b-input-group id="form-type-group" prepend="Payment For " label-for="form-type" class="my-2">
          <b-button-group>
            <b-button :pressed="select_students" @click="select_students=true; student=null" variant="outline-primary" style="border-radius: 0">
              Student
            </b-button>
            <b-button :pressed="!select_students" @click="select_students=false; family=null" variant="outline-primary">
              Family
            </b-button>
          </b-button-group>
        </b-input-group>
  
        <!--Student-->
          <b-input-group id="form-student-group" prepend="Student " label-for="form-student" class="my-2" v-if="select_students">
            <b-form-select id="form-student" v-model="student"  :options="students" required>
            </b-form-select>
          </b-input-group>

          <!--Family-->
          <b-input-group id="form-family-group" prepend="Family " label-for="form-family" class="my-2" v-else>
            <b-form-select id="form-family" v-model="family"  :options="families" required>
            </b-form-select>
          </b-input-group>

          <div class="text-danger" v-if="show_feedback && select_students && student == null">
            Please select a student
          </div>

          <div class="text-danger" v-if="show_feedback && !select_students && family == null">
            Please select a family
          </div>

        <!-- Credit
        <b-input-group id="form-credit-group" prepend="Credit" label-for="form-credit" class="my-2">
          <b-form-checkbox id="form-credit" v-model="credit">
          </b-form-checkbox>
        </b-input-group>
        -->

        <!-- Date -->
        <b-input-group id="form-date-group" prepend="Date" label-for="form-date" class="my-2">
          <b-form-input id="form-date" v-model="week_of" required type="date">
          </b-form-input>
        </b-input-group>

        <b-input-group id="form-price-group" prepend="Amount Paid $" label-for="form-price">
          <b-form-input type="number" step="0.01" id="form-amount" v-model="amount" required>
          </b-form-input>
        </b-input-group>

        <!-- Notes-->
        <b-input-group id="form-notes-group" prepend="Notes" label-for="form-notes" class="my-2">
          <b-form-textarea id="form-notes" v-model="notes" >
          </b-form-textarea>
        </b-input-group>
        
        <hr>
        <b-button type="submit" v-bind:variant="id != null ? 'primary' : 'success'">
          {{id != null ? 'Save' : 'Create'}}
        </b-button>
        <b-button type="reset" variant="clear" class="mx-1">Cancel</b-button>
        <b-button v-if="id != null" @click="deletePayment(id)" variant="danger" class="mx-1 float-right">Delete</b-button>
      </b-form>
  </b-modal>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";
const StudentAPI = ApiFactory.get("students");
const FamilyAPI = ApiFactory.get("family");
const PaymentsAPI = ApiFactory.get("payments");

var _ = require('lodash');

export default {
  name: 'PaymentModal',
  data: function(){
    return {
      id: null,
      select_students: true,
      students: [],
      families: [],
      student: null,
      family: null,
      credit: false,
      amount: null,
      week_of: null,
      notes: "",
      name: "",
      show_feedback: false
    }
  },

  methods:{
    new(startDate=null){
      this.initForm()
      if (startDate != null){
        this.week_of = startDate
      }
      this.$refs.paymentModal.show()
    },

    async edit(id){
      this.initForm()
      this.id = id;
      var {data} = await PaymentsAPI.getPayment(id);
      if (data.student != null){
        this.student = data.student.id
        this.name = data.student.name
        this.select_students = true;
      }else if (data.family != null){
        this.family = data.family.id
        this.name = data.family.name
        this.select_students = false;
      }
      this.notes = data.notes
      this.amount = data.amount / 100
      this.week_of = data.week_of
      this.$refs.paymentModal.show();
    },

    async deletePayment(id){
      var confirm = await window.confirm(`Are you sure you want to delete the payment for ${ this.name } on ${ this.week_of }`);
      if (confirm){
        var {status} = await PaymentsAPI.deletePayment(id)
        if (status == 204){
          this.$emit('delPayment', id)
          this.reset()
        }
        
      }
      
    },

    initForm(){
      if (this.students.length == 0){
        StudentAPI.getNoFamily().then((resp) =>{
          var unsorted_students = []
          for (var i=0; i < resp.data.length; i++){
            unsorted_students.push({
              value: resp.data[i].id, 
              text: resp.data[i].name,
              _last_name: resp.data[i].last_name
            });
          }
        this.students = _.orderBy(unsorted_students, ['_last_name'])
        this.students.push({value: null, text: " -Select Student -", disabled: true})
        });
      }

      if (this.families.length == 0){
        FamilyAPI.get_all().then((resp) => {
          var unsorted_families = []
          for (var i=0; i < resp.data.length; i++){
            unsorted_families.push({
              value: resp.data[i].id, 
              text: resp.data[i].name,
            });
          }
        this.families = _.orderBy(unsorted_families, ['text'])
        this.families.push({value: null, text: "- Select Family -", disabled: true})
        })
      }
      this.family = null
      this.student = null
      this.credit = false
      this.amount = null
      this.notes = ""
      this.id = null;
    },

    async submit(evt){
      evt.preventDefault();

      const payload = {
        amount: this.amount * 100,
        notes: this.notes,
        week_of: this.week_of,
      };

      if (this.family != null){
        payload.family = this.family
      }else if (this.student != null){
        payload.student = this.student
      }else{
        this.show_feedback = true
        return;
      }
      if (this.id == null){
        PaymentsAPI.createPayment(payload).then(({data}) =>{
          this.$emit('newPayment', data)
          this.$refs.paymentModal.hide()
        });
      }else{
        payload.id = this.id
        PaymentsAPI.updatePayment(payload).then(({data}) =>{
          this.$emit('newPayment', data)
          this.$refs.paymentModal.hide()
        });
      }

    },

    reset(){
      this.$refs.paymentModal.hide()
      this.initForm()
    },

  }

}
</script>